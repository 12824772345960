import { render, staticRenderFns } from "./reject-modal.vue?vue&type=template&id=b7a5ad38&scoped=true"
import script from "./reject-modal.vue?vue&type=script&lang=js"
export * from "./reject-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a5ad38",
  null
  
)

export default component.exports